import { getToken, setToken, removeToken } from '@/utils/auth'

const getDefaultState = () => {
    return {
        token: getToken(),
        name: '',
        avatar: ''
    }
}
const state = getDefaultState()

const mutations = {}
const actions = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}