import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export const constantRoutes = [
    {
        path: '/',
        name: 'HomePage',
        component: () => import('@/views/home/index')
    },
    {
        path: '/search',
        name: 'SearchPage',
        component: () => import('@/views/search/index')
    },
    {
        path: '/detail',
        name: 'DetailPage',
        component: () => import('@/views/detail/index')
    },
    {
        path: '/service',
        name: 'ServiceAbout',
        redirect: '/service/about',
        component: () => import('@/views/service/index'),
        children: [
          {
            path: 'about',
            name: 'AboutUs',
            component: () => import('@/views/service/components/about')
          },
          {
            path: 'license',
            name: 'License',
            component: () => import('@/views/service/components/license')
          },
          {
            path: 'terms',
            name: 'Terms',
            component: () => import('@/views/service/components/terms')
          },
          {
            path: 'privacy',
            name: 'Privacy',
            component: () => import('@/views/service/components/privacy')
          },
          {
            path: 'cookie',
            name: 'Cookie',
            component: () => import('@/views/service/components/cookie')
          },
          {
            path: 'digital',
            name: 'Digital',
            component: () => import('@/views/service/components/digital')
          }
        ]
    },
    { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })
  
const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router